import { AbstractAuditedModel } from './abstract-audited-model';
import { PlatformType } from '@core/data/type/platform.type';

export class Developer extends AbstractAuditedModel {
  // transient
  _checked = false;
  _possibleDuplicate = false;

  constructor(public name?: string,
              public username?: string,
              public email?: string,
              public url?: string,
              public avatarUrl?: string,
              public platformType?: PlatformType,
              public externalId?: string,
              public timeZoneOffset?: number, // minutes
              public parent?: Developer,
              public initials?: string,
              public children?: Developer[],
              public organizationId?: string) {
    super();
  }

  /**
   * This method basically returns the parent developer if it was set since this is a merged developer if its parent is set
   */
  get root(): Developer {
    return this.parent ? this.parent : this;
  }
}
