import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { Page } from '@core/data/model/page';
import { Developer } from '@core/data/model/developer';
import { DeveloperFilter } from '@core/data/model/dto/developer-filter';
import { List } from 'postcss/lib/list';
import { MergeDeveloper } from '@core/data/model/dto/merge-developer';
import { tap } from 'rxjs/operators';

@Injectable()
export class DeveloperService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('developer-service/developers', httpClient);
  }

  searchDevelopers(
    organizationPlatformResourceIds?: string[],
    searchPhrase?: string,
    page = 0,
    size = 30,
    sortKey = 'name',
    sortDir = 'asc'
  ): Observable<Page<Developer>> {
    const filter: DeveloperFilter = {
      searchPhrase,
      organizationPlatformResourceIds
    };
    return this.post<Page<Developer>>(`/search?page=${page}&size=${size}&sort=${sortKey},${sortDir}`, filter).pipe(
      tap(result => {
        result.content = result.content.map(dev => Object.assign(new Developer(), dev))
      })
    );
  }

  findDuplicates(developer: Developer): Observable<Developer[]> {
    return this.get<Developer[]>(`/${developer.id}/duplicates`);
  }

  mergeDevelopers(developer: Developer, duplicateDevelopers: Developer[]): Observable<void> {
    const mergeDeveloper: MergeDeveloper = {
      duplicateDeveloperIds: duplicateDevelopers.map(dev => dev.id)
    };
    return this.post<void>(`/${developer.id}/merge`, mergeDeveloper);
  }
}
